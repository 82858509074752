import React from "react";
import { useAuth } from "../../hooks/useAuth.hook";

function NavbarMobileSearch () {

  return (
    <div className="side_menu menu-opened">
      <div className="mobile_menu_header">
        <div className="close_nav">
          <i className="arrow_left"></i>
          <i className="icon_close"></i>
        </div>
        <div className="mobile_logo">
          <a href="/"><img src="/assets/img/logo.png" alt="logo" /></a>
        </div>
      </div>
      <div className="mobile_nav_wrapper">
        <nav className="mobile_nav_top">
          <ul className="navbar-nav menu ml-auto">
            <li className="nav-item">
              <a href="/" className="nav-link">Home1</a>
            </li>
            <li className="nav-item dropdown submenu">
              <a href="#" className="nav-link">Company</a>
              <i className="arrow_carrot-down_alt2 mobile_dropdown_icon"></i>
              <ul className="dropdown-menu">
                <li className="nav-item"><a href="Onepage.html" className="nav-link">Onepage</a></li>
                <li className="nav-item"><a href="contact.html" className="nav-link">Contact</a></li>
                <li className="nav-item"><a href="signin.html" className="nav-link">Sign In</a></li>
                <li className="nav-item"><a href="signup.html" className="nav-link">Sign Up</a></li>
                <li className="nav-item"><a href="typography.html" className="nav-link">Typography</a></li>
                <li className="nav-item"><a href="error.html" className="nav-link">404 Error</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown submenu">
              <a href="#" className="nav-link">Community</a>
              <i className="arrow_carrot-down_alt2 mobile_dropdown_icon"></i>
              <ul className="dropdown-menu">
                <li className="nav-item"><a href="Onepage.html" className="nav-link">Onepage</a></li>
                <li className="nav-item"><a href="contact.html" className="nav-link">Contact</a></li>
                <li className="nav-item"><a href="signin.html" className="nav-link">Sign In</a></li>
                <li className="nav-item"><a href="signup.html" className="nav-link">Sign Up</a></li>
                <li className="nav-item"><a href="typography.html" className="nav-link">Typography</a></li>
                <li className="nav-item"><a href="error.html" className="nav-link">404 Error</a></li>
              </ul>
            </li>
            
            <li className="nav-item dropdown submenu">
              <a href="#" className="nav-link">Apps</a>
              <i className="arrow_carrot-down_alt2 mobile_dropdown_icon"></i>
              <ul className="dropdown-menu">
                <li className="nav-item"><a href="Onepage.html" className="nav-link">Onepage</a></li>
                <li className="nav-item"><a href="contact.html" className="nav-link">Contact</a></li>
                <li className="nav-item"><a href="signin.html" className="nav-link">Sign In</a></li>
                <li className="nav-item"><a href="signup.html" className="nav-link">Sign Up</a></li>
                <li className="nav-item"><a href="typography.html" className="nav-link">Typography</a></li>
                <li className="nav-item"><a href="error.html" className="nav-link">404 Error</a></li>
              </ul>
            </li>
            
            <li className="nav-item dropdown submenu">
              <a href="#" className="nav-link">Partners</a>
              <i className="arrow_carrot-down_alt2 mobile_dropdown_icon"></i>
              <ul className="dropdown-menu">
                <li className="nav-item"><a href="Onepage.html" className="nav-link">Onepage</a></li>
                <li className="nav-item"><a href="contact.html" className="nav-link">Contact</a></li>
                <li className="nav-item"><a href="signin.html" className="nav-link">Sign In</a></li>
                <li className="nav-item"><a href="signup.html" className="nav-link">Sign Up</a></li>
                <li className="nav-item"><a href="typography.html" className="nav-link">Typography</a></li>
                <li className="nav-item"><a href="error.html" className="nav-link">404 Error</a></li>
              </ul>
            </li>
            
            <li className="nav-item">
              <a href="/" className="nav-link">Support</a>
            </li>
          </ul>
        </nav>
        <div className="mobile_nav_bottom">
          <aside className="doc_left_sidebarlist">
            <div className="scroll">
              <ul className="list-unstyled nav-sidebar">
                <li className="nav-item">
                  <a href="/" className="nav-link"><img src="assets/img/side-nav/home.png" alt="" />Aside navigation -- obsolete</a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
}

export default NavbarMobileSearch;