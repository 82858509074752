import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import NavbarSearchLanding from "../components/navbar/NavbarSearchLanding";
import FooterAdmin from "../components/footer/FooterSearchLanding";

const LayoutAdmin = () => {
  const [isAdmin] = useState(true);
  const [pageName, setPageName] = useState('');

  return (
    <>
      <div className="body_wrapper">

        <div className="header_top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <ul className="menu list-unstyled">
                  <li><a href="https://www.etrune.com" target="_blank">Marketplace</a></li>
                  <li><a href="https://community.etrune.com">Community</a></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="menu right-menu list-unstyled">
                  <li><a href="/item/send">Submit an item</a></li>
                  <li><a href="/">Check report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <NavbarSearchLanding pageName={pageName} />

        <Outlet context={{ setPageName }} />

        <FooterAdmin />
        
      </div >
    </>
  );
};

export default LayoutAdmin;