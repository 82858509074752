import React from "react";

function FooterSearch () {
  return (
    <footer className="simple_footer">
      <img className="leaf_right" src="/assets/img/home_one/leaf_footter.png" alt="" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <p>© {new Date().getFullYear()} All Rights Reserved by <a target="_blank" href="https://wwww.etrune.com/">ÊTRUNE</a></p>
          </div>
          <div className="col-sm-6 text-right">
            <ul className="list-unstyled f_social_icon">
              <li><a target="_blank" href="https://www.facebook.com/etrune.jewels"><i className="social_facebook"></i></a></li>
              <li><a target="_blank" href="https://www.instagram.com/etrune.jewels/"><i className="social_instagram"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterSearch;