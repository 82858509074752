import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";

async function navigateToQuery({ type, query }) {
  if (type === "report-id") {
    window.location.assign(
      `/search/details?${query.includes("item-") ? "id" : "report"}=${query}`
    );
  } else if (type === "query") {
    window.location.assign(`/search?q=${query || "all"}`);
  }
}

function queryInputFocus() {
  const input = document.querySelector("#query-input");
  input.focus();
}

function SearchLanding() {
  const location = useLocation();
  const [query, setQuery] = useState("");
  const [queryType, setQueryType] = useState("");
  const textOptions = [
    { title: "Round garnet with brilliant cut" },
    { title: "Oval sapphire with mixed cut" },
    { title: "Princess cut diamond" },
    { title: "Emerald cut emerald" },
    { title: "Cushion cut ruby" },
  ];
  const reportOptions = [
    { title: "3943" },
    { title: "4157" },
    { title: "4150" },
    { title: "4105" },
    { title: "3783" },
  ];

  const handleSubmit = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (queryType === "report-id" && !query) {
      return;
    }

    navigateToQuery({
      type: queryType,
      query,
    });
  };

  useEffect(() => {
    if (location.pathname.includes("report")) {
      setQueryType("report-id");
    } else {
      setQueryType("query");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (query) {
      handleSubmit({});
    }
  }, [query]);

  return (
    <>
      <section className="kbDoc-banner-support">
        <div className="banner-content text-center">
          <div className="banner-content-wrapper">
            <h1 className="banner-title wow fadeInUp text-etrune mb-4">
              ÊTRUNE
            </h1>
            <div
              className="banner-search-form-wrapper wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                autoComplete="off"
                className="banner-search-form"
              >
                {queryType === "report-id" && (
                  <section className="d-flex justify-content-center">
                    <div className="ml-3 my-auto px-2">
                      <span>Report:</span>
                    </div>
                  </section>
                )}
                <div className="search-input">
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={queryType === 'report-id' ? reportOptions.map((option) => option.title): textOptions.map((option) => option.title)}
                    onChange={(event, value) => {
                      setQuery(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        slotProps={{
                          input: {
                            ...params.InputProps,
                            type: "search",
                            disableUnderline: true,
                          },
                        }}
                      />
                    )}
                  />
                </div>

                <button type="submit">
                  <i className="icon_search"></i>
                </button>
              </form>
              <div className="mx-auto my-3">
                <button
                  className={
                    "mx-2 " +
                    (queryType === "query" ? "action_btn" : "doc_border_btn")
                  }
                  onClick={(_) => {
                    setQueryType("query");
                    queryInputFocus();
                  }}
                >
                  Explore
                </button>
                <button
                  className={
                    "mx-2 " +
                    (queryType === "report-id"
                      ? "action_btn"
                      : "doc_border_btn")
                  }
                  onClick={(_) => {
                    setQueryType("report-id");
                    queryInputFocus();
                  }}
                >
                  Report
                </button>
              </div>
            </div>
          </div>
        </div>

        <ul className="people-image wow fadeIn" data-wow-delay="0.7s">
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn1.jpg"
              alt="people"
            />
          </li>
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn2.jpg"
              alt="people"
            />
          </li>
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn3.jpg"
              alt="people"
            />
          </li>
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn4.jpg"
              alt="people"
            />
          </li>
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn5.jpg"
              alt="people"
            />
          </li>
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn6.jpg"
              alt="people"
            />
          </li>
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn7.jpg"
              alt="people"
            />
          </li>
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn8.jpg"
              alt="people"
            />
          </li>
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn9.jpg"
              alt="people"
            />
          </li>
          <li>
            <img
              className="wow zoomIn"
              data-wow-delay="0.4s"
              src="/assets/img/home_support/bn10.jpg"
              alt="people"
            />
          </li>
        </ul>
        <ul className="partical-animation wow fadeIn" data-wow-delay="0.9s">
          <li className="partical">
            <img
              data-parallax='{"x": -180, "y": 80, "rotateY":2000}'
              src="/assets/img/home_support/bnp1.png"
              alt="partical"
            />
          </li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical"></li>
          <li className="partical">
            <img
              src="/assets/img/home_support/bnp2.png"
              data-parallax='{"x": -250, "y": -160, "rotateZ":200}'
              alt="partical"
            />
          </li>
        </ul>
        <div className="bottom-shape">
          <img
            src="/assets/img/home_support/banner-bottom-shape.png"
            alt="banner"
          />
        </div>
      </section>

      <section className="communities">
        <div className="container">
          <div className="section_title title-img text-center">
            <img src="/assets/img/home_support/com_sm.png" alt="community" />

            <h2 className="title-lg wow fadeInUp">
              Ready to Design Your Future?
            </h2>

            <a
              href="https://www.etrune.com/"
              className="action_btn wow fadeInUp"
              data-wow-delay="0.3s"
            >
              Join the Innovation <i className="arrow_right"></i>
            </a>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="community-box wow fadeInUp" data-wow-delay="0.4s">
                <div className="icon-container">
                  <img
                    src="/assets/img/home_support/augmented-reality.webp"
                    alt="community"
                    className="cta-image-banner"
                  />
                </div>
                <div className="community-content">
                  <h3 className="com-title">
                    Co-create your unique engagement ring using augmented
                    reality.
                  </h3>
                  <a href="#" className="details-link">
                    Design Now <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="community-box wow fadeInUp" data-wow-delay="0.6s">
                <div className="icon-container">
                  <img
                    src="/assets/img/home_support/crowdfunding.webp"
                    alt="community"
                    className="cta-image-banner"
                  />
                </div>
                <div className="community-content">
                  <h3 className="com-title">
                    Empower your engagement ring vision through community
                    funding.
                  </h3>
                  <a href="#" className="details-link">
                    Fund your love <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="community-box wow fadeInUp" data-wow-delay="0.8s">
                <div className="icon-container">
                  <img
                    src="/assets/img/home_support/tradition-innovation.webp"
                    alt="community"
                    className="cta-image-banner"
                  />
                </div>
                <div className="community-content">
                  <h3 className="com-title">
                    Blend tradition and innovation to craft your future
                    heirloom.
                  </h3>
                  <a href="#" className="details-link">
                    Begin Your Journey <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SearchLanding;
