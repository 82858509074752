import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import NavbarSearch from "../components/navbar/NavbarSearch";
import NavbarMobileSearch from "../components/navbar/NavbarMobileSearch";
import FooterSearch from "../components/footer/FooterSearch";
import BreadcrumbSearch from "../components/breadcrumb/BreadcrumbSearch";

const LayoutSearch = () => {
  const [isAdmin] = useState(true);
  const [pageName, setPageName] = useState('');
  const [entityLayout, setEntityLayout] = useState({})

  useEffect(() => {
    const pageLoaded = new Event('view-loaded');
    window.dispatchEvent(pageLoaded);
  }, []);

  return (
    <>
      <div className="body_wrapper">

      <div className="header_top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <ul className="menu list-unstyled">
                  <li><a href="https://www.etrune.com" target="_blank">Marketplace</a></li>
                  <li><a href="https://community.etrune.com">Community</a></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="menu right-menu list-unstyled">
                  <li><a href="/item/send">Submit an item</a></li>
                  <li><a href="/">Check report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <NavbarSearch pageName={pageName} />

        <div className="click_capture"></div>

        <NavbarMobileSearch />

        <BreadcrumbSearch pageName={pageName} entityLayout={entityLayout} />

        <section className="doc_documentation_area w-99 d-flex align-items-stretch" id="sticky_doc">
          <div className="overlay_bg"></div>
          <div className="container-fluid d-flex align-items-stretch w-100 ml-0 pl-0">
            <Outlet context={{ setPageName, setEntityLayout }} />
          </div>
        </section>

        <FooterSearch />

      </div >
    </>
  );
};

export default LayoutSearch;