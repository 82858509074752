import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { ItemService } from "../../services/index";
import { SnapData, OnPageLoaded } from "@link-loom/ui-sdk";
import '@link-loom/ui-sdk/dist/styles.css';

import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";

function loadZoom() {
  window.setTimeout(() => {
    if (window.$(".zoom").length) {
      window.$(".zoom").magnify({
        afterLoad: function () {
          console.log("Magnification powers activated!");
        },
      });
    }
  }, 200);
}

function SearchDetails() {
  const { setPageName, setEntityLayout } = useOutletContext();
  const [entity, setEntity] = useState({});
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const reportType = params.id ? "id" : "report";

  useEffect(() => {
    setPageName("item-0001");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const entityService = new ItemService();
      const entityResponse = await entityService.getByParameters({
        queryselector: reportType,
        search: params.id || params.report || "",
      });

      console.log(entityResponse);

      if (!entityResponse?.success || !entityResponse?.result || !entityResponse?.result?.items?.length) {
        window.location.assign(`/not-found?id=${params.id}`);
        return;
      }

      setEntity(entityResponse?.result?.items?.[0] || {});
      setEntityLayout(entityResponse.result || {});

      loadZoom();
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <>
      <section className="container">
        <section className="row d-flex flex-fill">
          {/* Images */}
          <article className="col-12 col-lg-6 mt-4">
            <img
              className="img-fluid zoom d-block mx-auto border"
              src={
                entity?.media?.images?.[0] ||
                "/assets/img/preview-unavailable.jpg"
              }
              alt={"Etrune Gemstone" + entity?.name}
            />
          </article>

          {/* Data Content */}
          <article className="col-12 col-lg-6 mt-4">
            <header>
              <h2 className="text-etrune">
                {entity?.name} - {entity?.properties?.weight?.trade_weight}.
              </h2>
              <article className="text-muted mb-0">
                <small className="d-flex">
                  <span className="mr-2">Id:</span>
                  <SnapData
                    id="name"
                    data={entity?.id}
                    alignment="left"
                    icon={{className: "btn py-0 px-1 text-black-50 no-shadow small-85"}}
                    onEdit={() => {}}
                  />
                </small>
              </article>
              <article className="text-muted mb-0">
                <small className="d-flex">
                  <span className="mr-2">Report:</span>
                  <SnapData
                    id="name"
                    data={entity?.metadata?.report}
                    alignment="left"
                    icon={{className: "btn py-0 px-1 text-black-50 no-shadow small-85"}}
                    onEdit={() => {}}
                  />
                </small>
              </article>
            </header>
            <section className="mt-4">
              <TableContainer component={Paper}>
                <Table aria-label="caption table">
                  <caption>
                    Please note that all measurements are approximate. While we
                    strive to provide the most accurate data, slight variations
                    may occur depending on the measuring device used.
                  </caption>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="text-etrune"
                      >
                        Weight
                      </TableCell>
                      <TableCell align="right" className="text-etrune">
                        {entity?.properties?.weight?.trade_weight}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="text-etrune"
                      >
                        Size
                      </TableCell>
                      <TableCell align="right" className="text-etrune">
                        {entity?.properties?.size?.trade_measure}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="text-etrune"
                      >
                        Cut
                      </TableCell>
                      <TableCell align="right" className="text-etrune">
                        {entity?.properties?.cut?.name}{" "}
                        {entity?.properties?.cut?.style}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="text-etrune"
                      >
                        Lab grown
                      </TableCell>
                      <TableCell align="right" className="text-etrune">
                        {entity?.properties?.is_lab_grown ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </section>
          </article>
        </section>
      </section>
      <OnPageLoaded />
    </>
  );
}

export default SearchDetails;
