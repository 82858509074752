import React, { useState } from "react";
import {
  Box,
  styled,
  ThemeProvider,
  createTheme,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Slider,
  Select,
  MenuItem,
  InputLabel,
  Menu,
  MenuItem as MenuOption,
  Collapse,
} from "@mui/material";
import {
  ArrowRight,
  KeyboardArrowDown,
  Tune,
  Settings,
  Search,
  History,
  Favorite,
  Code,
  HelpOutline,
  HeadsetMic,
  ArrowBackIosNew,
} from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { MuiColorInput } from "mui-color-input";

const gemstoneNames = [
  "Amethyst",
  "Garnet",
  "Aquamarine",
  "Topaz",
  "Peridot",
  "Smoky quartz",
  "Opal",
  "Tourmaline",
  "Morganite",
  "Agate",
  "Citrine",
  "Quarts",
  "Moonstone",
  "Labradorite",
  "Tanzanite",
  "Iolite",
  "Rhodochrosite",
  "Cianite",
  "Emerald",
  "Ruby",
  "Sapphire",
  "Diamond",
  "Pearl",
  "Jade",
  "Coral",
  "Hematite",
  "Larimar",
  "Sultanite",
];

const gemstoneCutNames = [
  "Round",
  "Oval",
  "Princess",
  "Cushion",
  "Emerald",
  "Asscher",
  "Marquise",
  "Radiant",
  "Pear",
  "Heart",
  "Straight Baguette",
  "Kite",
  "Lozenge",
  "Trillion",
  "Hexagonal",
  "Octagonal",
  "Shield",
  "Bullet",
  "Briolette",
  "Cabochon",
  "Checkerboard",
  "Tapered Baguette",
  "Half Moon",
  "Free",
];

const gemstoneCutShapes = [
  "Round",
  "Oval",
  "Square",
  "Rectangular",
  "Navette",
  "Teardrop",
  "Heart",
  "Kite",
  "Rhombus",
  "Triangular",
  "Hexagonal",
  "Octagonal",
  "Shield",
  "Bullet",
  "Droplet",
  "Tapered",
  "Half Moon",
  "Free",
];

const gemstoneCutStyles = [
  "Brilliant",
  "Cabochon",
  "Carved",
  "Polished",
  "Rose",
  "Mixed",
  "Faceted",
  "Modified Brilliant",
  "Step",
  "Checkerboard",
  "Laser",
  "Scissor",
  "Bead",
  "Concave",
  "Barion",
];

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

function HeaderFilters({ isExpanded, setIsExpanded }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <ListItem component="section" disablePadding>
      <ListItemButton
        sx={{ height: 56 }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ListItemIcon sx={{ minWidth: isExpanded ? 56: 0 }}>
          <Tune color="primary" />
        </ListItemIcon>

        {isExpanded && (
          <ListItemText
            primary="Filters"
            primaryTypographyProps={{
              color: "primary",
              fontWeight: "medium",
              variant: "body2",
            }}
          />
        )}
      </ListItemButton>
      <Tooltip title="Settings">
        <>
          <IconButton
            size="large"
            onClick={handleSettingsClick}
            sx={{
              "& svg": {
                color: "rgba(255,255,255,0.8)",
                transition: "0.2s",
                transform: "translateX(0) rotate(0)",
              },
              "&:hover, &:focus": {
                bgcolor: "unset",
                "& svg:first-of-type": {
                  transform: "translateX(-4px) rotate(-20deg)",
                },
                "& svg:last-of-type": {
                  right: 0,
                  opacity: 1,
                },
              },
              "&::after": {
                content: '""',
                position: "absolute",
                height: "80%",
                display: "block",
                left: 0,
                width: "1px",
                bgcolor: "divider",
              },
            }}
          >
            <Settings />
            <ArrowRight sx={{ position: "absolute", right: 4, opacity: 0 }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuOption onClick={handleMenuClose}>
              <ListItemIcon>
                <Code fontSize="small" />
              </ListItemIcon>
              Advanced
            </MenuOption>
            <MenuOption onClick={handleMenuClose}>
              <ListItemIcon>
                <History fontSize="small" />
              </ListItemIcon>
              Recent Searches
            </MenuOption>
            <MenuOption onClick={handleMenuClose}>
              <ListItemIcon>
                <Favorite fontSize="small" />
              </ListItemIcon>
              Favorites
            </MenuOption>
            <MenuOption onClick={handleMenuClose}>
              <ListItemIcon>
                <HeadsetMic fontSize="small" />
              </ListItemIcon>
              Support
            </MenuOption>
          </Menu>
        </>
      </Tooltip>
    </ListItem>
  );
}

function SidebarSearch({ onPromptChange }) {
  const [openGeneral, setOpenGeneral] = useState(true);
  const [openColor, setOpenColor] = useState(false);
  const [openCut, setOpenCut] = useState(false);
  const [openSize, setOpenSize] = useState(false);
  const [openWeight, setOpenWeight] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [filters, setFilters] = useState({
    name: [],
    color: "",
    cutName: [],
    cutShape: [],
    cutStyle: [],
    isMulticolor: false,
    primaryColor: "#ffffff",
    secondaryColor: "#ffffff",
    height: [0, 0],
    width: [0, 0],
    depth: [0, 0],
    girdle: [0, 0],
    table: [0, 0],
    crown: [0, 0],
    pavilion_depth: [0, 0],
    pavilion_average_angle: [0, 0],
    weight_amount: [0, 0],
    weight_unit: "ct",
  });

  const handleFilterChange = (field) => (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFilters({ ...filters, [field]: value });
    if (onPromptChange) {
      onPromptChange(buildPrompt({ ...filters, [field]: value }));
    }
  };

  const handleAutocompleteChange = (field) => (event, value) => {
    setFilters({ ...filters, [field]: value });
    if (onPromptChange) {
      onPromptChange(buildPrompt({ ...filters, [field]: value }));
    }
  };

  const handleColorChange = (field) => (color) => {
    setFilters({ ...filters, [field]: color });
    onPromptChange(buildPrompt({ ...filters, [field]: color }));
  };

  const handleRangeChange = (field) => (event, value) => {
    setFilters({ ...filters, [field]: value });
    if (onPromptChange) {
      onPromptChange(buildPrompt({ ...filters, [field]: value }));
    }
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const buildPrompt = (filters) => {
    const parts = [];
    if (filters.name) parts.push(filters.name);
    if (filters.color) parts.push(filters.color);
    if (filters.cutName.length)
      parts.push(`cut names ${filters.cutName.join(", ")}`);
    if (filters.cutShape.length)
      parts.push(`shapes ${filters.cutShape.join(", ")}`);
    if (filters.cutStyle.length)
      parts.push(`styles ${filters.cutStyle.join(", ")}`);
    if (filters.isMulticolor) parts.push("multicolor");
    if (filters.primaryColor)
      parts.push(`with primary color ${filters.primaryColor}`);
    if (filters.secondaryColor)
      parts.push(`and secondary color ${filters.secondaryColor}`);
    parts.push(`height ${filters.height[0]}-${filters.height[1]}mm`);
    parts.push(`width ${filters.width[0]}-${filters.width[1]}mm`);
    parts.push(`depth ${filters.depth[0]}-${filters.depth[1]}mm`);
    parts.push(`girdle ${filters.girdle[0]}-${filters.girdle[1]}mm`);
    parts.push(`table ${filters.table[0]}-${filters.table[1]}mm`);
    parts.push(`crown ${filters.crown[0]}-${filters.crown[1]}mm`);
    parts.push(
      `pavilion depth ${filters.pavilion_depth[0]}-${filters.pavilion_depth[1]}mm`
    );
    parts.push(
      `pavilion average angle ${filters.pavilion_average_angle[0]}-${filters.pavilion_average_angle[1]}mm`
    );
    parts.push(
      `weight ${filters.weight_amount[0]}-${filters.weight_amount[1]}${filters.weight_unit}`
    );
    return `Find all ${parts.join(", ")}`;
  };

  return (
    <Box
      sx={{ borderRadius: 0 }}
      className={`d-flex ${
        isExpanded ? "sidebar-expanded" : "sidebar-collapsed"
      }`}
    >
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: "dark",
            primary: { main: "rgb(102, 157, 246)" },
            background: { paper: "rgb(5, 30, 52)" },
          },
        })}
      >
        <Paper
          elevation={0}
          className={`sidebar w-max-256px ${isExpanded ? "w-256px" : "w-auto"}`}
          sx={{ borderRadius: 0 }}
        >
          <Collapse
            in={isExpanded}
            timeout="auto"
            unmountOnExit
            className="d-block"
          >
            <FireNav component="nav" disablePadding>
              <HeaderFilters
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
              <Divider />
              <Box
                sx={{
                  bgcolor: openGeneral ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openGeneral ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenGeneral(!openGeneral)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openGeneral ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openGeneral ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="General"
                    secondary={openGeneral ? null : "Gems"}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openGeneral
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openGeneral ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openGeneral && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Autocomplete
                      multiple
                      options={gemstoneNames}
                      getOptionLabel={(option) => option}
                      value={filters.name || []}
                      onChange={handleAutocompleteChange("name")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Gemstone"
                          placeholder="Select gemstone"
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
              <Divider />
              <Box
                sx={{
                  bgcolor: openColor ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openColor ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenColor(!openColor)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openColor ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openColor ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="Color"
                    secondary={
                      openColor ? null : "Primary and secondary colors"
                    }
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openColor
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openColor ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openColor && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Box sx={{ mb: 2 }}>
                      <MuiColorInput
                        label="Primary Color"
                        value={filters.primaryColor}
                        onChange={handleColorChange("primaryColor")}
                        isAlphaHidden
                        format="hex"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters.isMulticolor}
                          onChange={handleFilterChange("isMulticolor")}
                          name="isMulticolor"
                          color="primary"
                        />
                      }
                      label="Is Multicolor"
                    />
                    {filters.isMulticolor && (
                      <Box sx={{ mb: 2 }}>
                        <MuiColorInput
                          label="Secondary Color"
                          value={filters.secondaryColor}
                          onChange={handleColorChange("secondaryColor")}
                          isAlphaHidden
                          format="hex"
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Divider />
              <Box
                sx={{
                  bgcolor: openCut ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openCut ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenCut(!openCut)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openCut ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openCut ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="Cut"
                    secondary={openCut ? null : "Name, shape, and style"}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openCut
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openCut ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openCut && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Autocomplete
                      multiple
                      options={gemstoneCutNames}
                      getOptionLabel={(option) => option}
                      value={filters.cutName}
                      onChange={handleAutocompleteChange("cutName")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Cut Name"
                          placeholder="Select cut names"
                        />
                      )}
                    />
                    <Autocomplete
                      multiple
                      options={gemstoneCutShapes}
                      getOptionLabel={(option) => option}
                      value={filters.cutShape}
                      onChange={handleAutocompleteChange("cutShape")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Shape"
                          placeholder="Select shapes"
                        />
                      )}
                    />
                    <Autocomplete
                      multiple
                      options={gemstoneCutStyles}
                      getOptionLabel={(option) => option}
                      value={filters.cutStyle}
                      onChange={handleAutocompleteChange("cutStyle")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Style"
                          placeholder="Select styles"
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
              <Divider />
              <Box
                sx={{
                  bgcolor: openSize ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openSize ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenSize(!openSize)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openSize ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openSize ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="Size"
                    secondary={
                      openSize
                        ? null
                        : "Height, width, depth, girdle, table, crown, pavilion depth, and pavilion average angle"
                    }
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openSize
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openSize ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openSize && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Height (mm)</Typography>
                      <Slider
                        value={filters.height}
                        onChange={handleRangeChange("height")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Width (mm)</Typography>
                      <Slider
                        value={filters.width}
                        onChange={handleRangeChange("width")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Depth (mm)</Typography>
                      <Slider
                        value={filters.depth}
                        onChange={handleRangeChange("depth")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Girdle (mm)</Typography>
                      <Slider
                        value={filters.girdle}
                        onChange={handleRangeChange("girdle")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Table (mm)</Typography>
                      <Slider
                        value={filters.table}
                        onChange={handleRangeChange("table")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Crown (mm)</Typography>
                      <Slider
                        value={filters.crown}
                        onChange={handleRangeChange("crown")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Pavilion Depth (mm)</Typography>
                      <Slider
                        value={filters.pavilion_depth}
                        onChange={handleRangeChange("pavilion_depth")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={50}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>
                        Pavilion Average Angle
                      </Typography>
                      <Slider
                        value={filters.pavilion_average_angle}
                        onChange={handleRangeChange("pavilion_average_angle")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={90}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              <Divider />
              <Box
                sx={{
                  bgcolor: openWeight ? "rgba(71, 98, 130, 0.2)" : null,
                  pb: openWeight ? 2 : 0,
                }}
              >
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setOpenWeight(!openWeight)}
                  sx={{
                    px: 3,
                    pt: 2.5,
                    pb: openWeight ? 0 : 2.5,
                    "&:hover, &:focus": {
                      "& svg": { opacity: openWeight ? 1 : 0 },
                    },
                  }}
                >
                  <ListItemText
                    primary="Weight"
                    secondary={openWeight ? null : "Amount and unit"}
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                      mb: "2px",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: 12,
                      lineHeight: "16px",
                      color: openWeight
                        ? "rgba(0,0,0,0)"
                        : "rgba(255,255,255,0.5)",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      opacity: 0,
                      transform: openWeight ? "rotate(-180deg)" : "rotate(0)",
                      transition: "0.2s",
                    }}
                  />
                </ListItemButton>
                {openWeight && (
                  <Box sx={{ px: 3, py: 2 }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom>Amount</Typography>
                      <Slider
                        value={filters.weight_amount}
                        onChange={handleRangeChange("weight_amount")}
                        valueLabelDisplay="auto"
                        min={0}
                        max={500}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>Unit</InputLabel>
                        <Select
                          value={filters.weight_unit}
                          onChange={handleFilterChange("weight_unit")}
                        >
                          <MenuItem value="ct">Carats (ct)</MenuItem>
                          <MenuItem value="g">Grams (g)</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                )}
              </Box>
              <Divider />
              <Box>
                <ListItemButton className="d-flex justify-content-end py-4" onClick={handleToggleExpand}>
                  <ListItemIcon>
                    <ArrowBackIosNew />
                  </ListItemIcon>
                </ListItemButton>
              </Box>
            </FireNav>
          </Collapse>

          {!isExpanded && (
            <HeaderFilters
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          )}
        </Paper>
      </ThemeProvider>
    </Box>
  );
}

export default SidebarSearch;
