import React from "react";

function FooterAdmin () {
  return (
    <footer className="footer_area f_bg_color">
      <img className="p_absolute leaf" src="/assets/img/v.svg" alt="" />
      <img className="p_absolute f_cloud" src="/assets/img/home_two/cloud.png" alt="" />
      <img className="p_absolute f_email" src="/assets/img/home_two/email-icon.png" alt="" />
      <img className="p_absolute f_email_two" src="/assets/img/home_two/email-icon_two.png" alt="" />
      <div className="footer_top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="f_widget subscribe_widget wow fadeInUp">
                <a href="/" className="f_logo"><img src="/assets/img/logo.png" srcSet="/assets/img/logo-2x.png 2x" alt="" /></a>
               
                <ul className="list-unstyled f_social_icon">
                  <li><a target="_blank" href="https://www.facebook.com/etrune.jewels"><i className="social_facebook"></i></a></li>
                  <li><a target="_blank" href="https://www.instagram.com/etrune.jewels/"><i className="social_instagram"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="f_widget link_widget pl_30 wow fadeInUp" data-wow-delay="0.2s">
                <h3 className="f_title">Company</h3>
                <ul className="list-unstyled link_list">
                  <li><a target="_blank" href="https://wwww.etrune.com/about">About Us</a></li>
                  <li><a target="_blank" href="https://wwww.etrune.com/partners">Partners</a></li>
                  <li><a target="_blank" href="https://careers.etrune.com/">Careers</a></li>
                  <li><a target="_blank" href="https://wwww.etrune.com/contact">Contact Us</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="f_widget link_widget wow fadeInUp" data-wow-delay="0.4s">
                <h3 className="f_title">Support</h3>
                <ul className="list-unstyled link_list">
                  <li><a target="_blank" href="https://support.etrune.com/help-desk">Help Desk</a></li>
                  <li><a target="_blank" href="https://support.etrune.com/manuals">Knowledge Base</a></li>
                  <li><a target="_blank" href="https://support.etrune.com/chat">Live Chat</a></li>
                  <li><a target="_blank" href="https://wwww.etrune.com/contact">Message us</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="f_widget link_widget pl_70 wow fadeInUp" data-wow-delay="0.6s">
                <h3 className="f_title">Legal</h3>
                <ul className="list-unstyled link_list">
                  <li><a target="_blank" href="https://wwww.etrune.com/terms">Terms & conditions</a></li>
                  <li><a target="_blank" href="https://wwww.etrune.com/privacy">Privacy policy</a></li>
                  <li><a target="_blank" href="https://support.etrune.com/legal">Customer service</a></li>
                  <li><a target="_blank" href="https://wwww.etrune.com/ethics">Ethics & compliance</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom text-center">
        <div className="container">
          <p>© {new Date().getFullYear()} All Rights Reserved by <a target="_blank" href="https://wwww.etrune.com/">ÊTRUNE</a></p>
        </div>
      </div>
    </footer>
  );
}

export default FooterAdmin;