import React from "react";

function NavbarSearchLanding(props) {
  return (
    <nav
      className="navbar navbar-expand-lg menu_one mt-40 menu_logo_change"
      id="sticky"
    >
      <div className="container">
        <a className="navbar-brand sticky_logo" href="/">
          <img
            src="/assets/img/logo.png"
            srcSet="/assets/img/logo-2x.png 2x"
            alt="logo"
          />
          <img
            src="/assets/img/logo.png"
            srcSet="/assets/img/logo-2x.png 2x"
            alt="logo"
          />
        </a>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="menu_toggle">
            <span className="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </span>
            <span className="hamburger-cross">
              <span></span>
              <span></span>
            </span>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav menu ml-auto dk_menu">
            <li className="nav-item dropdown">
              <a href="/" className="nav-link dropdown-toggle">
                Home
              </a>
            </li>
            <li className="nav-item dropdown submenu mega_menu tab-demo">
              <a
                href="https://etrune.com/pages/about-us"
                className="nav-link dropdown-toggle"
                target="_blank"
              >
                Company
              </a>
            </li>
            <li className="nav-item dropdown submenu">
              <a
                className="nav-link dropdown-toggle"
                href="https://community.etrune.com/"
                target="_blank"
              >
                Community
              </a>
            </li>
          </ul>
          <a className="nav_btn icon-btn round-btn" href="/">
            <i className="icon_headphones"></i>Support
          </a>
        </div>
      </div>
    </nav>
  );
}

export default NavbarSearchLanding;
